<template>
  <RLayout :title="$t('organization.teamMember.new')">
    <EcBox variant="card-1" class="width-full max-w-3xl mt-8 px-4 sm:px-10">
      <EcBox>
        <!-- Search -->
        <EcFlex class="flex-wrap max-w-full">
          <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="form.user_uid"
              componentName="EcSelect"
              :options="userOptions"
              :allowSelectNothing="true"
              :label="$t('organization.teamMember.user_uid')"
              :placeholder="$t('organization.teamMember.create_new')"
            />
          </EcBox>
        </EcFlex>

        <!-- Name -->
        <EcFlex class="flex-wrap max-w-full" v-if="!userSelected">
          <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
            <RFormInput
              v-model.trim="form.user_name"
              componentName="EcInputText"
              :label="$t('organization.name')"
              :validator="v$"
              field="form.user_name"
              @keyup="handleInputName"
              @input="v$.form.user_name?.$touch()"
            />
          </EcBox>
        </EcFlex>

        <!-- Email -->
        <EcFlex class="flex-wrap max-w-full" v-if="!userSelected">
          <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
            <RFormInput
              v-model.trim="form.user_email"
              componentName="EcInputText"
              :label="$t('organization.teamMember.email')"
              :validator="v$"
              field="form.user_email"
              @keyup="handleInputName"
              @input="v$.form.user_email?.$touch()"
            />
          </EcBox>
        </EcFlex>

        <!-- Phone Number -->
        <EcFlex class="flex-wrap max-w-full">
          <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
            <RFormInput
              v-model.trim="form.phone_number"
              componentName="EcInputText"
              :label="$t('organization.teamMember.phone')"
              :validator="v$"
              field="form.phone_number"
              @keyup="handleInputName"
              @input="v$.form.phone_number.$touch()"
            />
          </EcBox>
        </EcFlex>

        <!-- Organisation Role -->
        <EcFlex class="flex-wrap max-w-full">
          <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
            <RFormInput
              v-model.trim="form.organisation_role"
              componentName="EcInputText"
              :label="$t('organization.teamMember.organisationRole')"
              :validator="v$"
              field="form.organisation_role"
              @keyup="handleInputName"
              @input="v$.form.organisation_role.$touch()"
            />
          </EcBox>
        </EcFlex>

        <!-- Business Continuity Role -->
        <EcFlex class="flex-wrap max-w-full">
          <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
            <RFormInput
              v-model.trim="form.bc_team_role"
              componentName="EcInputText"
              :label="$t('organization.teamMember.bcRole')"
              :validator="v$"
              field="form.bc_team_role"
              @keyup="handleInputName"
              @input="v$.form.bc_team_role.$touch()"
            />
          </EcBox>
        </EcFlex>

        <!-- Divisions -->
        <EcFlex class="flex-wrap max-w-full">
          <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="form.divisions"
              componentName="EcMultiSelect"
              :label="$t('organization.teamMember.divisions')"
              :placeholder="$t('organization.teamMember.divisionsPlaceholder')"
              :options="divisionOptions"
              :valueKey="'uid'"
              :validator="v$"
              field="form.divisions"
              @input="v$.form.divisions.$touch()"
            />
          </EcBox>
          <EcSpinner v-if="isLoadingDivisions" class="ml-2 mt-8" />
        </EcFlex>

        <!-- Business Units -->
        <EcFlex class="flex-wrap max-w-full">
          <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="form.business_units"
              componentName="EcMultiSelect"
              :label="$t('organization.teamMember.businessUnits')"
              :placeholder="$t('organization.teamMember.businessUnitsPlaceholder')"
              :options="businessUnitOptions"
              :valueKey="'uid'"
              :validator="v$"
              field="form.business_units"
              @input="v$.form.business_units.$touch()"
            />
          </EcBox>
          <EcSpinner v-if="isLoadingBusinessUnits" class="ml-2 mt-8" />
        </EcFlex>
      </EcBox>
    </EcBox>

    <!-- Actions -->
    <EcBox class="width-full mt-8 px-4 sm:px-10">
      <!-- Button create -->
      <EcFlex v-if="!isLoading" class="mt-6">
        <EcButton variant="primary" id="btn-create" @click="handleClickCreate">
          {{ $t("organization.create") }}
        </EcButton>
        <EcButton class="ml-4" variant="tertiary-outline" id="cancel" @click="handleClickCancel">
          {{ $t("organization.cancel") }}
        </EcButton>
      </EcFlex>

      <!-- Loading -->
      <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
    </EcBox>
  </RLayout>
</template>

<script>
import { goto } from "@/modules/core/composables"
import { ref } from "vue"
import { useBusinessUnitList } from "../../use/business_unit/useBusinessUnitList"
import { useDivisionList } from "../../use/division/useDivisionList"
import { useTeamMemberNew } from "../../use/teamMember/useTeamMemberNew"
import { useTeamMemberUserList } from "../../use/teamMember/useTeamMemberUserList"

export default {
  name: "ViewOrganizationNew",
  data() {
    return {
      organizationUid: "",
      isLoading: false,
      userOptions: [],
      divisionOptions: [],
      isLoadingDivisions: false,
      businessUnitOptions: [],
      isLoadingBusinessUnits: false,
    }
  },

  computed: {
    userSelected() {
      return !!this.form.user_uid
    },
  },

  mounted() {
    const { organizationUid } = this.$route.params
    this.organizationUid = organizationUid

    this.fetchDivisions()
    this.fetchBusinessUnits()
    this.loadUserOptions()
  },
  setup() {
    const { v$, form, createTeamMember } = useTeamMemberNew()
    const { getUsers } = useTeamMemberUserList()
    const { getDivisions } = useDivisionList()
    const { getBusinessUnits } = useBusinessUnitList()
    const industries = ref([])

    return {
      v$,
      form,
      createTeamMember,
      industries,
      getUsers,
      getDivisions,
      getBusinessUnits,
    }
  },
  methods: {
    async loadUserOptions() {
      this.userOptions = await this.getUsers()
    },
    /**
     * Creaate orgranization
     */
    async handleClickCreate() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.isLoading = true
      const response = await this.createTeamMember(this.form)

      this.isLoading = false

      if (response && response.uid) {
        this.handleCreatedTeamMember()
      }
    },

    /**
     * Fetch divisions
     */
    async fetchDivisions() {
      this.isLoadingDivisions = true
      const divisions = await this.getDivisions({ per_page: 1000 })

      this.divisionOptions =
        divisions?.data?.map((div) => {
          return {
            name: div.name,
            uid: div.uid,
          }
        }) ?? []

      this.isLoadingDivisions = false
    },

    /**
     * Fetch business units
     */
    async fetchBusinessUnits() {
      this.isLoadingBusinessUnits = true
      const businessUnits = await this.getBusinessUnits({ per_page: 1000 })

      this.businessUnitOptions =
        businessUnits?.data?.map((bu) => {
          return {
            name: bu.name,
            uid: bu.uid,
          }
        }) ?? []

      this.isLoadingBusinessUnits = false
    },

    /**
     * Back to organization list
     */
    handleCreatedTeamMember() {
      goto("ViewDepartmentManagement")
    },
    /**
     * Back to organization list
     */
    handleClickCancel() {
      goto("ViewDepartmentManagement")
    },
  },
}
</script>
