import { useGlobalStore } from "@/stores/global"
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { ref } from "vue"
import * as api from "../../api/teamMemberFetcher"

export const useTeamMemberDetail = () => {
  // Global store
  const globalStore = useGlobalStore()

  const teamMember = ref({
    user_uid: null,
    user_name: "",
    user_email: "",
    phone_number: "",
    organisation_role: "",
    bc_team_role: "",
    divisions: [],
    business_units: [],
  })

  const rules = {
    teamMember: {
      user_name: { required },
      user_email: { required },
      phone_number: { required },
      organisation_role: { required },
      bc_team_role: { required },
      divisions: {},
      business_units: {},
    },
  }

  const v$ = useVuelidate(rules, { teamMember })

  /**
   * Get team member
   *
   * @param {*} uid
   * @returns
   */
  const getTeamMember = async (uid, params = {}) => {
    try {
      const { data } = await api.fetchTeamMember(uid, params)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return false
    }
  }

  /**
   *
   * @param {*} payload
   * @param {*} organizationUid
   * @param {*} uid
   * @returns
   */
  const updateTeamMember = async (payload, uid) => {
    try {
      const { data } = await api.updateTeamMember(payload, uid)

      globalStore.addSuccessToastMessage("Updated")
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return false
    }
  }

  /**
   *
   * @param {*} uid
   * @returns
   */
  const deleteTeamMember = async (uid) => {
    try {
      const { data } = await api.deleteTeamMember(uid)

      globalStore.addSuccessToastMessage("Deleted")
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return false
    }
  }

  return {
    teamMember,
    v$,
    getTeamMember,
    updateTeamMember,
    deleteTeamMember,
  }
}
