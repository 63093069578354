export default {
  organization: {
    organizations: "Organisations",
    name: "Name",
    desc: "Description",
    logo: "Logo",
    address: "Address",
    code: "Code",
    email: "Email",
    active: "Active",
    archive: "Archive",
    add: "Add",
    update: "Update",
    duplicate: "Duplicate",
    createdAt: "Created At",
    search: "Search",
    searchDivision: "Search Division",
    searchTeamMember: "Search Team Members",
    searchBU: "Search Business Unit",
    suspendOrganization: "Suspend organisation",
    noteSuspendOrganization: "We can suspend organisation",
    archiveOrganization: "Archive Organisation",
    deleteOrganization: "Delete organisation",
    noteDeleteOrganization:
      "You can permanently delete the organisation from database. This action cannot be reverted and you cannot restore the record anymore.",
    newOrganization: "New Organisation",
    create: "Create",
    cancel: "Cancel",
    back: "Back",
    yes: "Yes",
    no: "No",
    createOrganizationSuccess: "{org} organisation has been created successfully!",
    visitNewOrganization: "Do you want to visit the client portal now?",
    confirmToDelete: "Confirm to Delete",
    youAreGoingToDelete: "You are going to delete",
    organization: "organisation",
    // Delete
    actionCannotBeRevert: "This action cannot be reverted. The record will be deleted permanently.",
    confirmDeleteQuestion: "Are you sure you want to delete this organisation?",
    confirmDeletAction: "If so, type the organisation name into the box below to confirm",
    delete: "Delete",

    // Archive
    confirmToArchive: "Confirm to Archive",
    noteArchiveOrganization: "We can archive the organisation",
    confirmArchiveQuestion: "Do you want to archive this organisation",
    confirmArchiveAction: "If so, type the organisation name into the box below to confirm",

    updated: "Updated Organisation",
    duplicated: "Duplicated Organisation",
    deleted: "Deleted Organisation",
    source: "Source",
    friendlyUrl: "Friendly Url",

    relativeData: "Organisation Data",
    noteRelativeData: "Select corresponding checkbox to copy data from source organisation",

    teamMember: {
      new: "New Team Member",
      edit: "Edit Team Member",
      icon: "User Icon",
      name: "Name",
      description: "Description",
      email: "Email Address",
      phone: "Phone Number",
      organisationRole: "Organisation Role",
      bcRole: "Business Continuity Role",
      divisions: "Divisions (optional)",
      divisionsPlaceholder: "Select Divisions",
      businessUnits: "Business Units (optional)",
      businessUnitsPlaceholder: "Select Business Units",
      user_uid: "Select or Create a new Member",
      create_new: "Create new member",
      create: "Create",
      update: "Update",
      delete: "Delete",
      back: "Back",
      cancel: "Cancel",
      deleteHeader: "Delete Team Member",
      deleteNote:
        "You can permanently delete the team member from database. This action cannot be reverted and you cannot restore the record anymore.",
      deleteButton: "Delete team member",
      confirmDeleteQuestion: "Are you sure you want to delete this team member?",
    },
    // Division
    division: {
      new: "New Division",
      edit: "Edit Division",
      icon: "Department Icon",
      name: "Name",
      description: "Description",
      code: "Department Code",
      owner_id: "Owner",
      create: "Create",
      update: "Update",
      delete: "Delete",
      back: "Back",
      cancel: "Cancel",
      deleteHeader: "Delete Division",
      deleteNote:
        "You can permanently delete the division from database. This action cannot be reverted and you cannot restore the record anymore.",
      deleteButton: "Delete division",
      confirmDeleteQuestion: "Are you sure you want to delete this division?",
    },
    bu: {
      list: "Business Units",
      new: "New Business Unit",
      edit: "Edit Business Unit",
      icon: "Business Unit Icon",
      name: "Name",
      division: "Division",
      description: "Description",
      owner_id: "Owner",
      add: "Add",
      create: "Create",
      update: "Update",
      delete: "Delete",
      back: "Back",
      cancel: "Cancel",
      deleteHeader: "Delete Business Unit",
      deleteNote:
        "You can permanently delete the business unit from database. This action cannot be reverted and you cannot restore the record anymore.",
      deleteButton: "Delete Business Unit",
      confirmDeleteQuestion: "Are you sure you want to delete this business unit?",
    },
    owner: {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email Address",
      phone: "Phone number",
    },
    sortBy: {
      sortBy: "Sort by",
      byDivisionName: "Sort by Division name",
      byBusinessUnitName: "Sort by BU name",
    },
  },
}
